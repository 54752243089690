import { useEffect, useMemo, useState, useRef } from "react";
import { Stack, IconButton, ActionButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { AskResponse, ConversationMessage, getCitationFilePath, sendFeedback } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { LikeBtn } from "./LikeDislike";
import { DislikeForm } from "./DislikeForm";
import { ChatHelpRegular, Copy24Filled, Copy24Regular } from "@fluentui/react-icons";

const regex = /%%(.*?)%%/g;
const regexstar = /\*\*(.*?)\*\*/g;
//; //\*\*/g;
const replacerstar = (match: any, group: any) => {
    // group is the part between %% and %%
    // Return the html format with the group as the href attribute
    return `<strong>${group}</strong>`;
};
const replacer = (match: any, group: any) => {
    // group is the part between %% and %%
    // Return the html format with the group as the href attribute
    return `<a rel="noopener noreferrer" target="_blank" href="${group}"> here</a>`;
};

interface Props {
    // answer: string;
    message: ConversationMessage;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    // onThoughtProcessClicked: () => void;
    // onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    onSendFeedback: (messageId: number, action: string) => void;
    onSendFeedbackMessage: (messageId: number, feedback: string, feedbackCategory: string | null) => void;
    // onFeedbackClicked: (fb: "up" | "down") => void;
    // messageID: number;
}

export const Answer = ({
    // answer,
    message,
    isSelected,
    onCitationClicked,
    onSendFeedback,
    onSendFeedbackMessage,
    onFollowupQuestionClicked,
    showFollowupQuestions
}: // messageID
Props) => {
    //const [index, setIndex] = useState(0);
    const parsedAnswer = useMemo(() => parseAnswerToHtml(message.bot_input, onCitationClicked), [message.bot_input]);
    //console.log(parsedAnswer);
    //newly added
    //    const parseAnswerStar = useMemo(() => parseAnswerStarToHTML(message.bot_input), [message.bot_input]);

    let isThumbsUp = message.feedback == 1;
    let isThumbsDown = message.feedback == -1;
    //let sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    let sanitizedAnswerHtml = useMemo(() => DOMPurify.sanitize(parsedAnswer.answerHtml).replace(regex, replacer), [parsedAnswer]);
    //console.log(sanitizedAnswerHtml);
    let sanitizedAnswerHtmlBold = useMemo(() => DOMPurify.sanitize(sanitizedAnswerHtml).replace(regexstar, replacerstar), [parsedAnswer]);
    //console.log(sanitizedAnswerHtmlBold);
    //newly added
    //sanitizedAnswerHtml = useMemo(() => DOMPurify.sanitize(parseAnswerStar.answerHtml).replace(regexstar, replacerstar), [parsedAnswer]);

    const [feedbackForm, setFeedbackForm] = useState(false);
    /*
    let regex = /%%(.*?)%%/g;
    let replacer = (match: any, group: any) => {
        // group is the part between %% and %%
        // Return the html format with the group as the href attribute
        return `<a rel="noopener noreferrer" target="_blank" href="${group}"> here</a>`;
    };
    sanitizedAnswerHtml = sanitizedAnswerHtml.replace(regex, replacer);
    console.log(sanitizedAnswerHtml);
    */
    const divref = useRef<HTMLDivElement>(null);

    let ignore = false;
    useEffect(() => {
        if (message.feedback == -1) {
            setFeedbackForm(true);
        }
        return () => {
            ignore = true;
        };
    }, []);

    const [copySelect, setCopySelect] = useState<boolean>(false);

    const [isHoverAnswer, setHoverAnswer] = useState(false);

    const handleMouseEnter = () => {
        setHoverAnswer(true);
    };

    const handleMouseLeave = () => {
        setHoverAnswer(false);
    };

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Stack horizontal horizontalAlign="space-between" style={{ marginRight: "5px", justifyContent: "end", marginTop: "20px", height: "32px" }}>
                {/*<AnswerIcon />*/}
                {isHoverAnswer && (
                    <Stack
                        horizontal
                        style={{
                            backgroundColor: "#f9f9f9",
                            borderRadius: "5px",
                            width: "118px",
                            boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19)",
                            paddingLeft: "3px",
                            paddingRight: "3px"
                        }}
                    >
                        <LikeBtn
                            isSelected={isThumbsUp}
                            direction="Like"
                            onSendFeedback={onSendFeedback}
                            onSendFeedbackMessage={onSendFeedbackMessage}
                            setAnswerFeedbackForm={setFeedbackForm}
                            messageId={message.messageId}
                        />
                        <LikeBtn
                            isSelected={isThumbsDown}
                            direction="Dislike"
                            onSendFeedback={onSendFeedback}
                            onSendFeedbackMessage={onSendFeedbackMessage}
                            setAnswerFeedbackForm={setFeedbackForm}
                            messageId={message.messageId}
                        />
                        <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={styles.copySelectHover}>
                            {!copySelect && (
                                <div title="Copy respose here">
                                    <Copy24Regular
                                        style={{ color: "#2047ef", marginTop: "3.5px", cursor: "pointer" }}
                                        onClick={() => {
                                            setCopySelect(true);
                                            if (divref.current) {
                                                navigator.clipboard.writeText(String(divref.current.textContent));
                                            }
                                            setTimeout(() => {
                                                setCopySelect(false);
                                            }, 1000);
                                        }}
                                    />
                                </div>
                            )}
                            {copySelect && (
                                <Stack horizontal>
                                    <div className={styles.popup}>
                                        <div>
                                            <Copy24Filled style={{ color: "#2047ef", marginTop: "3.5px" }} />
                                        </div>
                                        <span className={styles.popuptext} id={styles.myPopup}>
                                            Copied
                                        </span>
                                    </div>
                                </Stack>
                            )}
                        </div>
                    </Stack>
                )}
            </Stack>
            <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
                <Stack.Item grow>
                    <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtmlBold }} ref={divref}></div>
                </Stack.Item>

                <Stack.Item>
                    <div></div>
                </Stack.Item>
                {!!parsedAnswer.citations.length || feedbackForm ? (
                    <Stack.Item>
                        <hr style={{ marginRight: "-20px", marginLeft: "-20px", opacity: "30%" }} />
                    </Stack.Item>
                ) : (
                    <div></div>
                )}

                {!!parsedAnswer.citations.length && (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>
                                <b>Learn more:</b>
                            </span>
                            {parsedAnswer.citations.map((x, i) => {
                                const path = getCitationFilePath(x);
                                return (
                                    <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                        {`${++i}. ${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}
                <Stack.Item>
                    <div></div>
                </Stack.Item>
                {feedbackForm && (
                    <Stack.Item grow>
                        <div className={styles.feedbackFormBackground}>
                            <DislikeForm
                                onSendFeedback={onSendFeedback}
                                onSendFeedbackMessage={onSendFeedbackMessage}
                                setAnswerFeedbackForm={setFeedbackForm}
                                messageId={message.messageId}
                                feedbackForm={feedbackForm}
                            />
                        </div>
                    </Stack.Item>
                )}
            </Stack>
            <br />

            {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack horizontal>
                    <span title="Follow-Up Questions" className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`}>
                        <ChatHelpRegular style={{ color: "#174ae4", fontSize: "26", marginRight: "4px" }} />
                    </span>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        {parsedAnswer.followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack>
            )}
        </div>
    );
};
