import { useRef, useState, useEffect, useCallback } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton, Stack } from "@fluentui/react";
//import { SparkleFilled } from "@fluentui/react-icons";
import fordLogo from "../../assets/fordIcon.png";
//import { Stack, ActionButton, Icon } from "@fluentui/react";
//import { ChatAdd28Regular, DocumentMargins20Regular } from "@fluentui/react-icons";

import styles from "./Chat.module.css";

import {
    chatApi,
    Approaches,
    AskResponse,
    ChatRequest,
    ChatTurn,
    ConversationMessage,
    sendFeedback,
    resetConversationId,
    getHistory,
    sendFeedbackMessage
} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";

import "../../assets/fonts/FordMotion/woff/FordMotion-Regular.woff";

const Chat = () => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(6);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(true);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<ConversationMessage[]>([]);

    const greetings = "Give a greetings to the user by mentioning their name, their role as well as the countries they can access!";

    useEffect(() => {
        //console.log("we are in the hook");
        if (sessionStorage.getItem("signout") !== "1") {
            getHistory()
                .then(h => {
                    const n = h.messages.length;
                    lastQuestionRef.current = n > 0 ? h.messages[n - 1].user_input : "";
                    error && setError(undefined);
                    setActiveCitation(undefined);
                    setActiveAnalysisPanelTab(undefined);
                    setAnswers(h.messages);
                })
                .catch(e => {
                    // setError(e.error);
                });
        } else {
            sessionStorage.setItem("signout", "");
            clearChat();
        }
    }, []);

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a.user_input, bot: a.bot_input }));
            //console.log("Value looking for : ", answers, history);
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined }],
                approach: Approaches.ReadRetrieveRead,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions
                }
            };
            const result = await chatApi(request);
            setAnswers(result.messages);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    // new code to copy
    const copyAllConversation = useCallback(() => {
        let allConversationCopyText = "";
        answers.forEach((answer, index) => {
            allConversationCopyText += `Q${index + 1} : ${answer.user_input}\nA${index + 1} : ${answer.bot_input}\n`;
        });
        navigator.clipboard.writeText(allConversationCopyText);
    }, [answers]);

    const makeApiRequestGreetings = async (question: string) => {
        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a.user_input, bot: a.bot_input }));
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined }],
                approach: Approaches.ReadRetrieveRead,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions
                }
            };
            const result = await chatApi(request);
            setAnswers(result.messages);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };
    const onSendFeedback = async (messageId: number, action: string) => {
        const result = await sendFeedback(messageId, action);
        setAnswers(result.messages);
    };
    const onSendFeedbackMessage = async (messageId: number, feedback: string, category: string | null) => {
        const result = await sendFeedbackMessage(messageId, feedback, category);
        setAnswers(result.messages);
    };
    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        resetConversationId();
        //makeApiRequestGreetings(greetings);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        const content = citation.split("/content/")[1];
        //console.log({ citation, index, content });
        if (content.match(/http(s)?:\/\/|www/)) {
            if (content.startsWith("www")) {
                window.open("https://" + content, "_blank");
            } else {
                window.open(content, "_blank");
            }
            return;
        } else if (content.startsWith("KA-")) {
            window.open("https://www.lifeatford.com/article/" + content, "_blank");
        }
        /*
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }
*/
        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };
    //<SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
    let ignore = false;
    useEffect(() => {
        if (sessionStorage.getItem("firstLoadDone") === null) {
            sessionStorage.setItem("firstLoadDone", "1");
            //makeApiRequestGreetings(greetings);
        }
        return () => {
            ignore = true;
        };
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.chatRoot}>
                <Stack>
                    <div className={styles.chatContainer}>
                        <div className={styles.chatEmptyState}>
                            <img src={fordLogo} alt="Ford Logo" aria-label="Ford" width="160px" height="160px" />
                            <h1 className={styles.chatEmptyStateTitle} style={{ fontFamily: "FordMotionRegular" }}>
                                Welcome to Pensions Chat - the new UK Pensions AI Tool
                            </h1>
                            <h3 className={styles.chatEmptyStateSubtitle} style={{ fontFamily: "FordMotionRegular" }}>
                                <strong>What is PensionsChat?</strong>
                                PensionsChat is a Beta version of an Artificial Intelligence tool designed to search knowledge articles in Life@Ford to provide
                                answers to general UK pensions questions. We recommend that you read the knowledge articles or external links identified by
                                PensionsChat in responses.
                                <br />
                                <br />
                                A few things to note about PensionsChat:
                                <br />
                                <br />
                                <strong>1. PensionsChat is not Perfect</strong>
                                As this is a Beta version of a new AI tool, you should not rely on PensionChat for any important actions in relation to your
                                pension. In some cases, PensionsChat may not be able to locate the relevant knowledge article and may not always have the
                                necessary knowledge to provide information to answer your question. It is also possible that PensionsChat may provide incorrect
                                or incomplete information. Please use your discretion to ensure that you receive the correct information and please speak to
                                People First and take independent financial advice if appropriate.
                                <br />
                                <br />
                                <strong>2. Add detail to your questions without Personal Information or advice requests</strong>
                                To help PensionsChat answer your question, please provide details on your specific situation for example, whether you are in the
                                Company’s Defined Benefit (Hourly or Salaried DB plan) or the Defined Contribution (L&G Mastertrust) plan but please do not ask
                                PensionsChat questions that contain Personal Information or ask for Pensions Advice.
                                <br />
                                <br />
                                <strong>3. Help improve PensionsChat by Providing Feedback</strong>
                                As this is an early version of an AI tool to help employees, your feedback is valuable to help improve the tool, please provide
                                comments and suggestions using the feedback buttons available with each answer. To monitor and improve PensionsChat, we may use
                                personal information, such as your name and email address. We will only use this information for the purposes of improving
                                PensionsChat. We will not share your personal information with third parties unless required by law to do so.
                                <br />
                                <br />
                            </h3>
                            {/*<h4 style={{ fontWeight: "600", textAlign: "justify", marginInline: "16%" }}>
                                * If you face any Technical related issue, please kindly report it by clicking&nbsp;
                                <a
                                    href="https://www.itconnect.ford.com/catalog/"
                                    target={"_blank"}
                                    style={{ display: "inline-block", whiteSpace: "nowrap" }}
                                    title="Report any Technical Issue here!"
                                >
                                    <u>here</u>
                                </a>
                                .
    </h4>*/}
                            <div style={{ color: "#2047ef" }}>_________________________________________________</div>
                        </div>

                        <div style={{ overflowY: "inherit", marginBottom: "1%" }}>
                            {!lastQuestionRef.current || lastQuestionRef.current === greetings ? (
                                <div className={styles.chatEmptyState}>
                                    <div className={styles.chatMessageStream}>
                                        {/* {answers.map((answer, index) => (
                                            <div key={index}>
                                                <div className={styles.chatMessageGpt}>
                                                    <Answer
                                                        key={index}
                                                        // messageID={answer.messageId}
                                                        message={answer}
                                                        isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                        onCitationClicked={c => onShowCitation(c, index)}
                                                        onSendFeedback={onSendFeedback}
                                                        onSendFeedbackMessage={onSendFeedbackMessage}
                                                        // onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                        // onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                        onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                        showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        {!isLoading && <ExampleList onExampleClicked={onExampleClicked} />}*/}
                                        {/*<ExampleList onExampleClicked={onExampleClicked} />
                                        {isLoading && (
                                            <>
                                                <div className={styles.chatMessageGptMinWidth}>
                                                    <AnswerLoading greetings={greetings} />
                                                </div>
                                            </>
                                        )}
                                        {error ? (
                                            <>
                                                <div className={styles.chatMessageGptMinWidth}>
                                                    <AnswerError error={error.toString()} onRetry={() => makeApiRequestGreetings(greetings)} />
                                                </div>
                                            </>
                                        ) : null}*/}
                                        <div ref={chatMessageStreamEnd} />
                                    </div>{" "}
                                </div>
                            ) : (
                                <div className={styles.chatMessageStream}>
                                    {answers.map((answer, index) => (
                                        <div key={index}>
                                            <UserChatMessage message={answer.user_input} greetings={greetings} />
                                            <div className={styles.chatMessageGpt}>
                                                <Answer
                                                    key={index}
                                                    // messageID={answer.messageId}
                                                    message={answer}
                                                    isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                    onCitationClicked={c => onShowCitation(c, index)}
                                                    onSendFeedback={onSendFeedback}
                                                    onSendFeedbackMessage={onSendFeedbackMessage}
                                                    // onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                    // onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                    onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                    showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    {isLoading && (
                                        <>
                                            <UserChatMessage message={lastQuestionRef.current} greetings={greetings} />
                                            <div className={styles.chatMessageGptMinWidth}>
                                                <AnswerLoading />
                                            </div>
                                        </>
                                    )}
                                    {error ? (
                                        <>
                                            <UserChatMessage message={lastQuestionRef.current} greetings={greetings} />
                                            <div className={styles.chatMessageGptMinWidth}>
                                                <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                            </div>
                                        </>
                                    ) : null}
                                    <div ref={chatMessageStreamEnd} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.chatContainerQuestion}>
                        <div className={styles.chatInputBackground}></div>
                        <div className={styles.chatInput}>
                            <QuestionInput
                                clearOnSend
                                placeholder="Type a new question here..."
                                disabled={isLoading}
                                onSend={question => makeApiRequest(question)}
                                onNewChatButtonClick={clearChat}
                                currentQuestion={lastQuestionRef.current}
                                //copyAllConversation={copyAllConversation}
                                greetings={greetings}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </Stack>

                {/* {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )} */}

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many documents from search:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField
                        className={styles.chatSettingsSeparator}
                        label="Webroles Filter (Ex Format: Global, Global HR)"
                        onChange={onExcludeCategoryChanged}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />
                </Panel>
            </div>
        </div>
    );
};

export default Chat;
