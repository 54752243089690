import { useState, useEffect } from "react";
import { Stack, TextField, ActionButton } from "@fluentui/react";
import { Send28Regular, Send28Filled, ChatAdd28Regular } from "@fluentui/react-icons";
import { ArrowExitFilled } from "@fluentui/react-icons";
import styles from "./QuestionInput.module.css";

import "../../assets/fonts/FordMotion/woff/FordMotion-Regular.woff";

interface Props {
    onSend: (question: string) => void;
    onNewChatButtonClick: () => void;
    //copyAllConversation: () => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    currentQuestion?: string;
    greetings?: string;
    isLoading?: boolean;
}

export const QuestionInput = ({
    onSend,
    //copyAllConversation,
    disabled,
    placeholder,
    clearOnSend,
    currentQuestion,
    greetings,
    onNewChatButtonClick,
    isLoading
}: Props) => {
    const [question, setQuestion] = useState<string>("");

    const maxLength = 1000;

    const sendQuestion = () => {
        if (disabled || !question.trim() || isLoading) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= maxLength) {
            setQuestion(newValue);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight
    });

    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight
        });
    };
    useEffect(() => {
        window.addEventListener("resize", detectSize);

        return () => {
            window.removeEventListener("resize", detectSize);
        };
    }, [windowDimension]);

    return (
        <Stack horizontal>
            {windowDimension.winWidth <= 575 ? (
                <ActionButton
                    className={styles.animatedButton}
                    style={{ padding: "12px 12px" }}
                    onClick={onNewChatButtonClick}
                    disabled={!currentQuestion || disabled || currentQuestion === greetings}
                    title="New chat"
                >
                    <ChatAdd28Regular style={{ marginRight: "0px" }} />
                </ActionButton>
            ) : (
                <ActionButton
                    className={styles.animatedButton}
                    style={{ padding: !question ? "12px 28px" : "12px 12px", fontFamily: "FordMotionRegular" }}
                    onClick={onNewChatButtonClick}
                    disabled={!currentQuestion || disabled || currentQuestion === greetings}
                    title="New chat"
                >
                    <ChatAdd28Regular style={{ marginRight: !question ? "5px" : "0px" }} />
                    {!question ? "New chat" : <span className={styles.ref}></span>}
                </ActionButton>
            )}
            <Stack horizontal className={styles.questionInputContainer} style={{ marginRight: "20px" }}>
                <TextField
                    className={styles.questionInputTextArea}
                    placeholder={placeholder}
                    multiline
                    resizable={false}
                    borderless
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                    title="Type your question here"
                    style={{ fontFamily: "FordMotionRegular", fontSize: "16px", fontWeight: "500" }}
                />
                <div className={styles.questionInputButtonsContainer}>
                    <Stack
                        className={`${styles.questionInputSendButton} ${sendQuestionDisabled ? styles.questionInputSendButtonDisabled : ""}`}
                        aria-label="Ask question button"
                        onClick={sendQuestion}
                        title="Send"
                        style={{ alignItems: "end", marginLeft: "10px" }}
                    >
                        <div>
                            {disabled || !question.trim() || isLoading ? <Send28Regular primaryFill="#747474" /> : <Send28Filled primaryFill="#2047ef" />}
                        </div>
                        <div style={{ fontSize: "12px", fontFamily: "FordMotionRegular" }}>
                            {question.length}/{maxLength}
                        </div>
                    </Stack>
                </div>
            </Stack>

            {windowDimension.winWidth <= 575 ? (
                <ActionButton
                    className={styles.animatedButton}
                    style={{ padding: "12px 12px" }}
                    onClick={() => (window.location.href = "https://www.lifeatford.com/")} // Update onClick
                    //disabled={!currentQuestion || disabled || currentQuestion === greetings}
                    title="Exit Conversation"
                >
                    <ArrowExitFilled style={{ marginRight: "0px" }} />
                </ActionButton>
            ) : (
                <ActionButton
                    className={styles.animatedButton}
                    style={{ padding: !question ? "12px 28px" : "12px 12px", fontFamily: "FordMotionRegular" }}
                    onClick={() => (window.location.href = "https://www.lifeatford.com/")} // Update onClick
                    //disabled={!currentQuestion || disabled || currentQuestion === greetings}
                    title="Exit Conversation"
                >
                    <ArrowExitFilled style={{ marginRight: !question ? "5px" : "0px" }} />
                    {!question ? "Exit Conversation" : <span className={styles.ref}></span>}
                </ActionButton>
            )}
            {/*windowDimension.winWidth <= 575 ? (
                <ActionButton
                    className={styles.animatedButton}
                    style={{ padding: "12px 12px" }}
                    onClick={copyAllConversation} // Update onClick
                    disabled={!currentQuestion || disabled || currentQuestion === greetings}
                    title="Copy Conversation"
                >
                    <ArrowExitFilled style={{ marginRight: "0px" }} />
                </ActionButton>
            ) : (
                <ActionButton
                    className={styles.animatedButton}
                    style={{ padding: !question ? "12px 28px" : "12px 12px", fontFamily: "FordMotionRegular" }}
                    onClick={copyAllConversation} // Update onClick
                    disabled={!currentQuestion || disabled || currentQuestion === greetings}
                    title="Copy Conversation"
                >
                    <ArrowExitFilled style={{ marginRight: !question ? "5px" : "0px" }} />
                    {!question ? "Copy Conversationn" : <span className={styles.ref}></span>}
                </ActionButton>
            )*/}
        </Stack>
    );
};
